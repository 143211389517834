.iframe {
  min-height: 500px
}

@media screen and (max-width: 1400px) {
   .iframe {
     min-height: 450px;
   }     
}


@media screen and (min-width: 1640px) {
  .iframe {
    min-height: 590px;
  }
}

.btn-next:hover,
.btn-prev:hover {
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}