ul.progress-list {
    list-style: none;
    display: flex;
    width: 250px;
    margin: 0;
    padding: 0;
    justify-content: space-between;
}

li.progress-list {
    display: flex;
    align-items: center;
    color: grey;
}

.square {
    padding:5px;
    border: 1px solid black;
}