nav {
  position: fixed;
  display: flex;
  max-height: 100;
  justify-content: space-between;
  padding: 1.5rem 0.5rem;
  align-items: center;
  min-width: 100vw;
  z-index: 1000;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}
.logo {
  cursor: pointer;
  transition: 0.5s;
}

.logo:hover {
  transform: scale(1.1);
}

p {
  margin: 0;
}

.nav_block {
  align-items: center;
  display: flex;
  margin-right: 2rem;
}

.nav_block div {
  margin: 0 1rem;
}

.navlist {
  margin: 0;
  padding: 0%;
  display: flex;
  list-style: none;
}
a,
.list-item {
  cursor: pointer;
  font-size: 15px;
  margin: 0 1rem;
  color: #666666;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif;
}

a:hover {
  color: #fff;
  text-decoration: none;
}
.student_profile {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.student_profile p {
  color: #666666;
  font-size: 15px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif;
  margin: 0 1rem;
}

.modo_dia {
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 50%;
  padding: 7px;
  color: #fff;
}

.logout {
  cursor: pointer;
}
.row.menu {
  display: none;
}

.burger {
  cursor: pointer;
  transition: 0.3s;
}

.burger:hover {
  transform: scale(1.2);
  opacity: 0.6;
}

.menu-handler-button {
  background-color: #6bb6c9;
  border-radius: 10px;
}

@media screen and (max-width: 1024px) {
  .student_profile {
    display: none;
  }
  .modo_dia {
    display: none;
  }
  .logout {
    display: none;
  }
  .list {
    display: none;
  }
  .row.menu {
    display: flex;
    justify-content: flex-end;
    padding-right: 1rem;
  }
  .nav_block {
    margin-right: 1rem;
  }
}
