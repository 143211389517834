

header {
  background-color:  #69b7cb;
/*   background-image: url('./assets/img-interior24.jpg'); */
  background-size: cover;
  background-repeat: no-repeat;
}

/* .header_block {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50%;
} */

/* h1 {
  font-family: Pop-Bold;
  font-size: 40px;
  color: #fff;
  padding: 50px 0 20px 0;
  margin: 0;
  letter-spacing: 2.57px;
} */

.img-fluid {
  max-width: 100%;
  height: auto;
}

.loader {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 150px 0;
}