.ring {
  --uib-size: 25px;
  --uib-speed: 2s;
  --uib-color: #fff;

  height: var(--uib-size);
  width: var(--uib-size);
  vertical-align: middle;
  transform-origin: center;
  animation: rotate var(--uib-speed) linear infinite;
}

.ring circle {
  fill: none;
  stroke: var(--uib-color);
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  stroke-linecap: round;
  animation: stretch calc(var(--uib-speed) * 0.75) ease-in-out infinite;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes stretch {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 90, 200;
    stroke-dashoffset: -35px;
  }

  100% {
    stroke-dashoffset: -124px;
  }
}

.contactFormBlock {
  justify-content: center;
  width: 60%;
  margin: 0 auto;
  padding-bottom: 5rem;
  padding-top: 100px;
}
.inputContactForm {
  justify-content: center;
  width: 100%;
}
.formGroup {
  justify-content: center;
  width: 100%;
  margin-top: 20px;
}
.buttonForm {
  margin: auto 0 0 auto;
}
.tituloFormContacto {
  margin-top:20px;
}

.loading {
  width:50%;
  margin: 20% 20%;
  font-size: 40px;
}

.formError{
  color:#f7000d!important;
}
@media screen and (min-width:1181px){
  .contactFormBlock{
    height:70vh;
  }
}
@media screen and (min-width:481px) and (max-width:1180px){
  .contactFormBlock{
    height:70%;
  }
  .loading {
    width:100%;
    margin: 30% -20px;
    font-size: 40px;
  }

}

@media screen and (max-width:480px){
  .contactFormBlock{
    width:100%;
    padding-left: 10px;
    padding-right: 10px;
  }

  .loading {
    width:100%;
    margin: 30% -20px;
    font-size: 40px;
  }
}

.contact-form {
  display: flex;
  justify-content: center;
}