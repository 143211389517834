.breadcrumb-ul {
  display: flex;
  list-style: none;
  padding: 0;
  margin-top: 1.5rem;
}

.breadcrumb-li {
  margin: 0 .5rem;
  color: grey;
  font-weight: 400;
  font-size: 14px;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif;
}

.breadcrumb-ul a {
  margin: 0;
  color: grey;
  font-weight: 400;
}