.sidebar-container {
  position: fixed;
  width: 320px;
  left: -350px;
  height: 100vh;
  background-color: rgb(43 162 191);
  z-index: 1001;
  transition: .6s;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 3rem 3rem;
  top: 0;
  bottom: 0;
}

.top {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20%;
}

.top-content {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding: 0 1.5rem;
}

.top-content img {
  width: 25px;
}

.avatar {
  width: 100px !important;
}


.center {
  height: 60%;
}

.center-content {
  padding: 1rem ;
}

.center-content ul {
  list-style: none;
}
.center-content ul li {
  font-size: 18px;
  color: #fff;
  display: flex;
  align-items: flex-start;
}
.center-content ul li img{
  margin-right: 1.2rem;
  width: 20px;
  margin-top: .2rem;
}
.bottom {
  height: 20%;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  justify-content: flex-end;
}

.bottom div {
  display: flex;
  justify-content: center;
}
