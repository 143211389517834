.route {
  border: 1px solid #fff;
  height: 13px;
  width: 13px;
  margin-right: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.route-inner {
  border: 1px solid #fff;
  height: 8px;
  width: 8px;
  background-color: #ffeb3b;
  border-radius: 50%;
}
.resource-container {
  transition: 0.4s;
}
.resource-container-label {
  margin-top: 15px;
  font-size: 12px;
  opacity: 0.7;
}

.resource-container:hover {
  transition: 0.4s;
  font-size: 18px;
}
