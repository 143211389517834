@charset "UTF-8";
@font-face {
  font-family: Pop-Bold ;
  src: local('Lato'), url(./fonts/Poppins-Bold.ttf) format('opentype');
}

@font-face {
  font-family: Pop-Medium;
  src: local('Lato'), url(./fonts/Poppins-Medium.ttf) format('opentype');

}

@font-face {
  font-family: Pop-Regular ;
  src: local('Lato'), url(./fonts/Poppins-Regular.ttf) format('opentype');
}


.img-fluid {
  max-width: 100%;
  height: auto;
}

* {
transition:  background-color 0.3s ease;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.title-row {
  cursor: pointer;
}
h1.historial, th{
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif !important;
  color: #68b7ca !important;
}

td {
  color: grey !important;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif !important ;
}

.filecontainer {
  border-radius: 5px;
  transition: 0.3s
}

.list-item-chart {
  font-size: 15px;
  margin: 0 1rem;
  color: grey;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif;
}


.filecontainer:hover {
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}

.chart-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
th.title-row-detail {
  color: grey !important;
}

@media screen and (max-width: 820px) {
  .group-chart-container{
    display:flex!important;
    flex-direction: column;
    align-items: center;
  }
}

@media screen and (min-width: 821px) and (max-width: 1024px) {
  .group-chart-container{
    display: grid!important;
    grid-template-columns: repeat(2, 1fr)!important;
  }
}